.container {
  display: flex;
  width: 100%;
  height: calc(100% - 60px);
  align-items: center;
  justify-content: space-around;
}

.inner {
  max-width: 28rem;
  width: 100%;
  padding: 3rem;
  background-color: #ffffff;
  border-radius: 0.5rem;
}

.inner form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.inner button {
  width: 100%;
  height: 60px;
  margin-bottom: 3rem;
}

.inner p,
.inner a,
.inner a:visited {
  color: inherit;
  margin: 0 auto;
  border-bottom: 1px solid transparent;
  margin-bottom: 0.75rem;
  transition: all 150ms;
  cursor: pointer;
  text-decoration: none;
}
.inner p:hover,
.inner a:hover {
  color: #1960ff;
  border-color: #1960ff;
}

.inner p[color='danger'],
.inner p[color='danger']:hover {
  color: red;
  border: none;
  cursor: default;
  margin-bottom: 1rem;
}

.loading {
  text-align: center;
  padding-top: 1rem;
}
